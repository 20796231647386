import {useMutation, useQueryClient} from "@tanstack/react-query";
import {APP_API} from "./App";
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";

function DeleteHouse() {
    const {getIdToken} = useKindeAuth();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (data) => {
            const authToken = await getIdToken();
            if (!authToken) {
                console.log("No token found");
                return;
            }
            console.log(data);
            await fetch(`${APP_API}/api/house/${data}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': authToken
                },
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['board']})
        }
    })
}

export default DeleteHouse;