import * as React from 'react';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Divider from '@mui/joy/Divider';
import Typography from '@mui/joy/Typography';
import Button from "@mui/joy/Button";
import LaunchIcon from '@mui/icons-material/Launch';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteHouse from "../DeleteHouse";
import {useNavigate} from "react-router-dom";
import Dropdown from "@mui/joy/Dropdown";
import MenuButton from "@mui/joy/MenuButton";
import Menu from "@mui/joy/Menu";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

export default function HouseCard({house, handleSnackbarOpen}) {
    const deleteHouse = DeleteHouse();
    const openSnackbar = () => {
        handleSnackbarOpen()
    }

    const handleDelete = () => {// Disable drag-and-drop when delete button is clicked
        openSnackbar();
        deleteHouse.mutate(house.id);
    }

    const navigate = useNavigate();
    const navigateToHouse = () => {
        navigate("/house/" + house.id);
    }

    return (
        <>
            <CardContent>
                <Typography level="title-sm">
                    {house.title}
                </Typography>
                <Typography level="body-sm">
                    {house.address}
                </Typography>
            </CardContent>
            <CardOverflow variant="soft">
                <Divider inset="context"/>
                <CardContent orientation="horizontal" sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 0,
                    padding: 0,
                    paddingTop: 2,
                    paddingBottom: 2,
                }}>
                    <div>
                        <Typography level="body-xs">{house.price}</Typography>
                        <Divider orientation="vertical"/>
                        <Typography level="body-xs" sx={{alignItems: 'center'}}>Added
                            on: {house.addedOn}</Typography>
                        <Divider orientation="vertical"/>
                    </div>
                    <Dropdown>
                        <MenuButton variant="plain">
                            <Button
                                size="sm"
                                variant="plain"
                                color="primary"
                            >
                                <MoreVertIcon/>
                            </Button>
                        </MenuButton>
                        <Menu>
                            <Button
                                onClick={() => navigateToHouse()}
                                size="sm"
                                variant="plain"
                                color="primary"
                            >
                                <LaunchIcon/>
                            </Button>
                            <Button
                                size="sm"
                                variant="plain"
                                color="danger"
                                onClick={() => handleDelete()}
                            >
                                <DeleteRoundedIcon/>
                            </Button>
                        </Menu>
                    </Dropdown>
                </CardContent>
            </CardOverflow>
        </>
    );
}
