import './App.css';
import {Route, Routes} from "react-router-dom";
import Homepage from "./pages/Homepage";
import Dashboard from "./components/Dashboard";
import {CssVarsProvider} from "@mui/joy/styles";
import {KindeProvider} from "@kinde-oss/kinde-auth-react";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import HousePage from "./pages/HousePage";

export const APP_API = "https://toppi.app";

function AppRoutes() {
    const queryClient = new QueryClient();
    const APP_REDIRECT_URI = "https://toppi.app";

    return (
        <CssVarsProvider disableTransitionOnChange>
            <QueryClientProvider client={queryClient}>
                <KindeProvider
                    clientId="bc2666981ab74fff92a95922bab6bf4a"
                    domain="https://toppiapi.kinde.com"
                    redirectUri={`${APP_REDIRECT_URI}/dashboard`}
                    logoutUri={APP_REDIRECT_URI}
                >
                    <Routes>
                        <Route path="/" element={<Homepage/>}/>
                        <Route path="/dashboard" element={<Dashboard/>}/>
                        <Route path="/house/:houseId" element={<HousePage/>}/>
                    </Routes>
                </KindeProvider>
            </QueryClientProvider>
        </CssVarsProvider>
    );
}

function App() {
    return <AppRoutes/>;
}

export default App;
