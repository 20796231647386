import {useQuery} from "@tanstack/react-query";
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
import {APP_API} from "./App";

const useHouse = (houseId) => {
    const {getIdToken} = useKindeAuth();

    const getHouseById = async () => {
        const authToken = await getIdToken();
        if (!authToken) {
            return null
        }
        const fetchData = await fetch(`${APP_API}/api/house/${houseId}`, {
            headers: {
                'Authorization': authToken
            }
        });
        if (!fetchData.ok) {
            return null
        }
        return await fetchData.json();
    };

    return useQuery({
        queryKey: ['getHouse', houseId],
        queryFn: getHouseById,
        enabled: !!houseId
    });
};

export default useHouse;