import {Breadcrumbs, Card, Grid} from "@mui/joy";
import Button from "@mui/joy/Button";
import {useNavigate, useParams} from "react-router-dom";
import Link from "@mui/joy/Link";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import CardContent from "@mui/joy/CardContent";
import {ImageList, ImageListItem} from '@mui/material';
import useHouse from "../useHouse.js";
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import BedIcon from '@mui/icons-material/Bed';
import BathtubIcon from '@mui/icons-material/Bathtub';
import SquareFootIcon from '@mui/icons-material/SquareFoot';

export default function HousePage() {
    const {houseId} = useParams();
    console.log("House ID: ", houseId);
    const navigate = useNavigate();
    const navigateToDashboard = () => {
        navigate("/dashboard");
    }
    const {data: house, isLoading, error} = useHouse(houseId);
    console.log("House Data: ", house);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <Box sx={{flex: 1, width: '100%'}}>
            <Box>
                <Breadcrumbs>
                    <Link>
                        <Button variant="plain" color="primary" onClick={() => navigateToDashboard()}>
                            Dashboard
                        </Button>
                    </Link>
                    <Link>
                        <Typography level="title-sm" color="primary">
                            House
                        </Typography>
                    </Link>
                </Breadcrumbs>
            </Box>
            <Stack
                spacing={4}
                sx={{
                    display: 'flex',
                    maxWidth: '800px',
                    mx: 'auto',
                    px: {xs: 2, md: 6},
                    py: {xs: 2, md: 3},
                }}
            >
                <Card variant="outlined" sx={{width: '720px'}}>
                    <ImageList cols={3} rowHeight={164}>
                        {house?.photos.map((item) => (
                            <ImageListItem key={item.img}>
                                <img
                                    srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                    src={`${item}?w=164&h=164&fit=crop&auto=format`}
                                    alt={"house"}
                                    loading="lazy"
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                    <CardContent>
                        <Link
                            level="h1"
                            underline="none"
                            rel="noopener"
                            target="_blank"
                            color="neutral"
                            href={house.link}
                        >
                            {house.title}</Link>
                        <Typography level="h5">{house.address}</Typography>
                        <Grid container spacing={2} sx={{my: 2}}>
                            <Grid item xs={6} sm={3}>
                                <Box display="flex" alignItems="center">
                                    <CurrencyPoundIcon color="primary" sx={{fontSize: "32px"}}/>
                                    <Typography level="body" sx={{ml: 1}}>{house.price}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Box display="flex" alignItems="center">
                                    <BedIcon color="primary" sx={{fontSize: "32px"}}/>
                                    <Typography level="body" sx={{ml: 1}}>{house.bedrooms}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Box display="flex" alignItems="center">
                                    <BathtubIcon color="primary" sx={{fontSize: "32px"}}/>
                                    <Typography level="body" sx={{ml: 1}}>{house.bathrooms}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Box display="flex" alignItems="center">
                                    <SquareFootIcon color="primary" sx={{fontSize: "32px"}}/>
                                    <Typography level="body" sx={{ml: 1}}>{house.floorSpaceFt} ft²
                                        / {house.floorSpaceSqm} m²</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{my: 2}}>
                            <Grid item xs={6} sm={4}>
                                <Typography level="body" level="title-md">Added On: {house.addedOn}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <Typography level="body" level="title-md">Property
                                    Type: {house.propertyType}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <Typography level="body"
                                            level="title-md">Status: {house.status.charAt(0).toUpperCase()
                                    + house.status.slice(1)}</Typography>
                            </Grid>
                        </Grid>
                        <Typography level="body" level="title-md">Key Features:</Typography>
                        <Typography>{house.keyFeatures}</Typography>
                        <Typography level="body" level="title-md">Description:</Typography>
                        <Typography level="body">{house.propertyDescription}</Typography>
                    </CardContent>
                </Card>
            </Stack>
        </Box>
    )
        ;
}